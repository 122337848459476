<script>
export default {
  locales: {
    pt: {
      Username: "Usuário",
      Sponsored: "Indicados",
      "Donation Received": "Doações Recebidas",
      Cycle: "Ciclo",
      cycle: "ciclo",
      ACTIVE: "ATIVO",
      INACTIVE: "INATIVO",
      PENDING: "PENDENTE",
      COMPLETED: "COMPLETO",

      Boards: "Tabuleiros",
      Code: "Código",
      "Donations Received": "Doações Recebidas",
      "Created in": "Criado em",
    },
    es: {
      Username: "Usuário",
      Sponsored: "Indicados",
      "Donation Received": "donaciones recibidas",
      Cycle: "Ciclo",
      cycle: "ciclo",
      ACTIVE: "ACTIVO",
      INACTIVE: "INACTIVO",
      PENDING: "PENDENTE",
      COMPLETED: "COMPLETO",

      Boards: "Tableros",
      Code: "Código",
      "Donations Received": "Donaciones Recibidas",
      "Created in": "Creado en",
    },
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <div class="card bg-light text-dark">
    <div class="card-body">
      <div v-if="type == 'center'" class="text-center">
        <h5
          v-if="value == 'active'"
          class="mb-0 notranslate text-white font-weight-bold"
        >
          <span class="bg-success rounded text-dark px-2">{{
            t("ACTIVE")
          }}</span>
        </h5>
        <h5
          v-else-if="value == 'inactive'"
          class="mb-0 notranslate text-white font-weight-bold"
        >
          <span class="bg-danger rounded text-dark px-2">{{
            t("INACTIVE")
          }}</span>
        </h5>
        <h5
          v-else-if="value == 'pending'"
          class="mb-0 notranslate text-dark font-weight-bold"
        >
          <span class="bg-danger rounded text-white px-2">{{
            t("PENDING")
          }}</span>
        </h5>
        <h5
          v-else-if="value == 'approved'"
          class="mb-0 notranslate text-dark font-weight-bold"
        >
          <span class="bg-success rounded text-white px-2">{{
            t("ACTIVE")
          }}</span>
        </h5>
        <h5
          v-else-if="value == 'completed'"
          class="mb-0 notranslate text-dark font-weight-bold"
        >
          <span class="bg-success rounded text-white px-2">{{
            t("COMPLETED")
          }}</span>
        </h5>
        <h5 v-else class="mb-0 notranslate text-dark font-weight-bold">
          {{ t(value) }}
        </h5>
        <p
          class="font-weight-medium mb-0 text-uppercase font-weight-light"
          v-html="t(title)"
        ></p>
      </div>
      <div v-else>
        <p
          class="font-weight-medium mb-0 text-uppercase font-weight-light"
          v-html="t(title)"
        ></p>
        <h5
          v-if="title == 'Cycle'"
          class="mb-0 notranslate text-dark font-weight-bold"
        >
          <span>{{ value }}º {{ t("cycle") }}</span>
          <span v-if="value > 1"> – Infinity</span>
        </h5>
        <h5
          v-else-if="value == 'active'"
          class="mb-0 notranslate text-dark font-weight-bold"
        >
          <span class="bg-success rounded text-white px-2">{{
            t("ACTIVE")
          }}</span>
        </h5>
        <h5
          v-else-if="value == 'inactive'"
          class="mb-0 notranslate text-dark font-weight-bold"
        >
          <span class="bg-danger rounded text-white px-2">{{
            t("INACTIVE")
          }}</span>
        </h5>
        <h5
          v-else-if="value == 'pending'"
          class="mb-0 notranslate text-dark font-weight-bold"
        >
          <span class="bg-danger rounded text-white px-2">{{
            t("PENDING")
          }}</span>
        </h5>
        <h5
          v-else-if="value == 'approved'"
          class="mb-0 notranslate text-dark font-weight-bold"
        >
          <span class="bg-success rounded text-white px-2">{{
            t("ACTIVE")
          }}</span>
        </h5>
        <h5
          v-else-if="value == 'completed'"
          class="mb-0 notranslate text-dark font-weight-bold"
        >
          <span class="bg-success rounded text-white px-2">{{
            t("COMPLETED")
          }}</span>
        </h5>
        <h5 v-else class="mb-0 notranslate text-dark font-weight-bold">
          {{ t(value) }}
        </h5>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg {
  background-color: #e0e0e0;
}
.card-body {
  padding: 1rem 1.25rem 0.8rem 1.25rem;
  line-height: 15px;
}
h5 {
  margin: 0;
}
</style>
